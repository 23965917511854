/* purgecss start ignore */
@tailwind base;

@tailwind components;

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(48, 48, 48, 0.75);
}
.Modal {
  position: absolute;
  top: 40px;
  left: 40px;
  right: 40px;
  bottom: 40px;
}

/* .custom {
  margin-bottom: 2rem;
} */

.modal {
  z-index: 100;
  position: fixed;
  left: 5%;
  right: 5%;
}

@media only screen and (max-width: 1024px) and (min-width: 768px) {
  /* .custom {
    width: 30%;
    margin-bottom: 0;
  } */
}

@media (min-width: 1281px) {
  .main {
    margin-left: 390px;
  }
}
@media only screen and (max-width: 1280px) and (min-width: 1023px) {
  .main {
    margin-left: 342px;
  }
}

@media (min-width: 1023px) {
  .modal {
    z-index: 100;
    position: fixed;
    top: 11vh;
    left: 30%;
    width: 65%;
  }
}

@media (min-width: 768px) and (max-width: 1022px) {
  .modal {
    z-index: 100;
    position: fixed;
    top: 11vh;
    left: 5%;
    right: 5%;
    overflow: scroll;
    height: 100%;
  }
}

@media (min-width: 318px) and (max-width: 767px) {
  .modal {
    z-index: 100;
    position: fixed;
    overflow-y: scroll;
    height: 100%;
    left: 5%;
    right: 5%;
  }
}

@import url("https://fonts.googleapis.com/css2?family=Roboto&family=Yeseva+One&display=swap");

/* purgecss end ignore */
@tailwind utilities;
